import React from "react";
import { useState, useContext } from "react";
import LogIn from "./LogIn.tsx";
import Onboarding from "./Onboarding.tsx";
import Header from "../Header.tsx";
import UserContext from "../UserContext.ts";
import { Link } from "react-router-dom";

const Welcome = ({ bankName }) => {
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [showLogIn, setShowLogIn] = useState(false);
    const ctx  = useContext(UserContext);
    const toggleLogIn = () => {
        setShowOnboarding(false);
        setShowLogIn(!showLogIn);}
    
    const toggleOnboarding = () => {
        setShowLogIn(false); 
        setShowOnboarding(!showOnboarding); }
    
    return (
    <section>
        <Header bankName={bankName} />

        { ctx && ctx.user.type == 'user'? (
            <>
                <p>Velkommen, {ctx.user.name}!</p>
                <Link to="/bank">Gå til banktenester</Link>
            </>
        ) : (
            <>
                {showLogIn && <LogIn />}
                {showOnboarding && <Onboarding setVisible={setShowOnboarding} />}

                <button className="buttonStyle" onClick={toggleLogIn}>Logg inn</button>
                &nbsp;eller&nbsp; 
                <button className="buttonStyle" onClick={toggleOnboarding}>bli kunde</button>
            </>
        )}
        
    </section>)
}

export default Welcome;