import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Welcome from './Welcome';
import PublicInfo from './PublicInfo';
import NotFound from './NotFound';

const Guest = ({ bankName }) => {
    return (
        <Routes>
            <Route path="/" element={<Welcome bankName={bankName} />} />
            <Route path="/pub" element={<PublicInfo bankName={bankName} />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default Guest;