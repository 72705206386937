import React from "react";
import { Link, Route, Routes } from 'react-router-dom';

const NotFound = () => (
    <div>
        <h2>Her kan du ikkje vere</h2>
        <Link to="/">Gå til forsida</Link>
    </div>
)

export default NotFound;