import React, { useState } from 'react';

type User = {
    type: 'user';
    name: string;
    token: string;
}

type Guest = {
    type: 'guest';
}

export type UserContextType = {
    user: UserType;
    setUser: React.Dispatch<React.SetStateAction<UserType>>;
}

export type UserType = User | Guest;

const UserContext = React.createContext<UserContextType|undefined>(
    undefined);

export default UserContext;