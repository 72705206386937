import React from 'react';
import Header from '../Header';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import Summary from './Summary';
import Transactions from './Transactions';
import Accounts from './Accounts';
import Payments from './Payments';
import NotFound from '../offentleg/NotFound';
import UserContext from '../UserContext';
import { useContext } from 'react';

const Authenticated = ( {bankName} ) => {
    const ctx = useContext(UserContext);
    if (!ctx) return (<Navigate to="/"/>);
    if (ctx.user.type !== 'user') return (<Navigate to="/"/>); 

    return (
        <>
        <Header bankName={bankName} />
        <nav>
            <ul>
                <li><Link to="/bank/">Oversikt</Link></li>
                <li><Link to="/bank/transactions">Transaksjoner</Link></li>
                <li><Link to="/bank/accounts">Konti</Link></li>
                <li><Link to="/bank/payments">Betalingar</Link></li>
            </ul>
        </nav>
        <Routes>
            <Route path='/' element={ <Summary bankName={bankName}/> } />
            <Route path='/transactions' element={ <Transactions bankName={bankName}/> } />
            <Route path='/accounts' element={ <Accounts bankName={bankName}/> } />
            <Route path='/payments' element={ <Payments bankName={bankName}/> } />
            <Route path='*' element={ <NotFound /> } />
        </Routes>
        </>
    );
};

export default Authenticated;