// App.tsx
import React, { useState, useEffect, useContext } from 'react';
import UserContext, {UserType} from './UserContext';
import Authenticated from './bank/Authenticated';
import Guest from './offentleg/Guest';
import Cookies from 'js-cookie';
import { Route, Routes } from 'react-router-dom';

const App = ({tenantInfo}) => {
    let userCookie: UserType | undefined;
    try {
        userCookie = JSON.parse(Cookies.get('user')) as UserType;
        }
    catch (e) {
        console.log('No user cookie');
    }

    const [user, setUser] = useState<UserType>(userCookie || { type: 'guest' });
 
    useEffect(() => {
        Cookies.set('user', JSON.stringify(user));
    }
    , [user]);

    const ctx = {user, setUser};

    return (
        <UserContext.Provider value={ctx}>
            <Routes>
                <Route path="/bank/*" element={<Authenticated bankName={tenantInfo.label} />} />
                <Route path="/*" element={<Guest bankName={tenantInfo.label} />} />
            </Routes>
        </UserContext.Provider>
    );
};

export default App;
