import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './common/App';
import './public/styles/common.css';
import { BrowserRouter } from 'react-router-dom';

const fetchBankName = async () => {
    const response = await fetch('/api/bank', {
        headers: {
            'tenant': 'sparebanken-sunnarvik' // Just for testing, ingress will replace this
        //    'tenant': 'viste-sparebank' // Just for testing, ingress will replace this
        }
    });
    const data = await response.json();
    return data;
};

fetchBankName().then((tenantInfo) => {
    switch (tenantInfo.id) {
        case 'sparebanken-sunnarvik':
            import ('./public/styles/sparebanken-sunnarvik/nettbank.css');
            break;
        case 'viste-sparebank':
            import ('./public/styles/viste-sparebank/nettbank.css');
            break;
        default:
            import ('./public/styles/nettbank.css');
    }

    const container=document.getElementById('root');
    const rootElement = ReactDOM.createRoot(container!);
    rootElement.render(
        <React.StrictMode>
            <BrowserRouter>
                <App tenantInfo={tenantInfo} />
            </BrowserRouter>
        </React.StrictMode>
    );
});

