import React, { useContext } from "react";
import UserContext, { UserType } from "./UserContext";
import { Navigate, useNavigate } from "react-router-dom";

const User = () => {
    const userContext = useContext(UserContext);
    const user = userContext!.user;
    return (
        <div>
            {user.type === "user" ? (
                <div className="titleUser">
                    <h2>{user.name}</h2>
                    <button onClick={() => 
                            {
                                userContext!.setUser({ type: "guest" });
                                // Navigate to the home page
                                window.location.href = "/";

                            }                        
                        }>
                        Logg ut
                        {/* <Navigate to="/" /> */}
                    </button>
                </div>
            ) : (
                <div>
                    <p>Du er ikke logget inn</p>
                    <>Logg inn</>
                </div>
            )}
        </div>  
    );
};

export default User;