import React from "react";

const PublicInfo = ({ bankName }) => (
    <div>
        <h1>Nettbank {bankName}</h1>
        <p>Velkommen til nettbanken</p>
        Offentleg informasjon om banken.
    </div>
)

export default PublicInfo;