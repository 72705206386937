import React, { ChangeEventHandler, FormEventHandler, useContext } from "react";
import { useState } from "react";
import UserContext from "../UserContext";
import { Navigate } from "react-router-dom";

type FormData = {
    alias?: string;
    password?: string;
}
type FormErrors = {
    userName?: string;
    password?: string;
}

const LogIn = () => {
    const userContext = useContext(UserContext);
    const [formData, setFormData] = useState({alias:'',password:''} as FormData);
    const [formErrors, setFormErrors] = useState({userName:'',password:''} as FormErrors);
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [errorData, setErrorData] = useState('');
    const { user, setUser } = userContext!;
    const handleChange:ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setFormErrors(validateForm(formData));
    };

    const validateForm = (formData: FormData) => {
        let errors = {} as FormErrors;
        Object.keys(formData).forEach(key => {
            if (!formData[key].trim()) {
                errors[key] = `${key} is required`;
            }
        });
        return errors;
    };
    const handleSubmit:FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
    
        // Initialize or clear previous submission status
        setSubmissionStatus(''); // Assuming you have a state to track this
    
        // Validate form data before submitting
        const errors = validateForm(formData);
        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }
    
        try {
            const response = await fetch('/api/users/passwordcheck', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'tenant': 'sparebanken-sunnarvik' // Blir overskriven av ingress
                },
                body: JSON.stringify(formData),
            });
    
    
            if (!response.ok) {
                const errorData = await response.json();
                setErrorData(errorData);
                
                throw new Error(`Error: ${response.status} - ${errorData}`);
            }
    
            console.log('Success: check password');
            setSubmissionStatus('success'); // Update submission status to success
            // set user context
            setUser({type: 'user', name: formData.alias!, token: '1234'});
        } catch (error) {
            console.error('Submission failed:', error.message, errorData);
            setSubmissionStatus('error'); // Update submission status to error
        }
    };

    if (submissionStatus === 'success') {
        return (
            <div className="form-container">
                <Navigate to="/bank" />
            </div>
        );
    }

    return (
        <div className="form-container">
            <h1>Logg inn</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <label htmlFor="alias">Fornavn</label>
                    <input type="text" id="alias" name="alias" value={formData.alias} onChange={handleChange} />
                    {formErrors.userName && <p className="error">{formErrors.userName}</p>}
                    </div>
    

                <div className="form-row">
                    <label htmlFor="password">Passord</label>
                    <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} />
                    {formErrors.password && <p className="error">{formErrors.password}</p>}
                    </div>

                <button type="submit">Logg inn</button>
            </form>
            {submissionStatus === 'error' && <p className="error">{errorData};
            </p>}
        </div>
    );}

export default LogIn;