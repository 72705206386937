import React, {useState, useEffect, useContext} from "react";
import UserContext, {UserContextType} from "../UserContext";

type FormData = {
    firstName: string;
    lastName: string;
    dob: string;
    alias: string;
    password: string;
    confirmPassword: string;
};

type Errors = {
    firstName?: string;
    lastName?: string;
    dob?: string;
    alias?: string;
    password?: string;
    confirmPassword?: string;
};

type StateActions = string | null

const Onboarding = ({setVisible}) => {
    // const [user, setUser] = useState<UserContextType>({type: 'guest'});
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        alias: '',
        password: '',
        confirmPassword: '',
    } as FormData);
    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        alias: '',
        password: '',
        confirmPassword: '',
    } as Errors);
    const [submissionStatus, setSubmissionStatus] = useState<StateActions>(null);

    const currentDate = new Date();
    const defaultDOB = new Date(currentDate.getFullYear() - 25, currentDate.getMonth(), currentDate.getDate()).toISOString().split('T')[0];
    const minDOB = new Date(currentDate.getFullYear() - 120, currentDate.getMonth(), currentDate.getDate()).toISOString().split('T')[0];
    const maxDOB = new Date().toISOString().split('T')[0]; // Today's date

    const handleChange:React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setFormErrors(validateForm(formData));
    };

    const validateForm = (formData: FormData) => {
        let errors = {} as Errors;
    
        // Check for empty fields
        Object.keys(formData).forEach(key => {
            if (!formData[key].trim()) {
                errors[key] = `${key} is required`;
            }
        });
    
        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            errors.password = 'Passwords do not match';
            errors.confirmPassword = 'Passwords do not match';
        }
    
        return errors;
    };
    const handleSubmit:React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
    
        // Initialize or clear previous submission status
        setSubmissionStatus(''); // Assuming you have a state to track this
    
        // Validate form data before submitting
        const errors = validateForm(formData);
        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }
    
        try {
            const response = await fetch('/api/users/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'tenant': 'sparebanken-sunnarvik'
                },
                body: JSON.stringify(formData),
            });
    
    
            if (!response.ok) {
                // Handle backend-specific error messages
                const errorMessage = `Error: ${response.status} - ${response.statusText}`;
                throw new Error(errorMessage);
            }
    
            console.log('Success: created');
            setSubmissionStatus('success'); // Update submission status to success
            // Optionally, redirect the user or clear the form here
        } catch (error) {
            console.error('Submission failed:', error.message);
            setSubmissionStatus('error'); // Update submission status to error
            // Display error.message in your UI to inform the user
        }
    };

    if (submissionStatus === 'success') {
        return (
            <div className="form-container">
                <h1>Velkommen!</h1>
                <p>Du kan no logge inn med den nye kontoen din.</p>
                <button onClick={() => setVisible(false)}>Lukk</button>
            </div>
        );
    }
    return (
        <div className="form-container">
            <h1>Velkommen som kunde i banken</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <label htmlFor="firstName">Fornavn</label>
                    <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} />
                    {formErrors.firstName && <p className="error">{formErrors.firstName}</p>}
                    </div>
    
                <div className="form-row">
                    <label htmlFor="lastName">Etternavn</label>
                    <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} />
                    {formErrors.lastName && <p className="error">{formErrors.lastName}</p>}
                    </div>
    
                <div className="form-row">
                    <label htmlFor="dob">Fødselsdato</label>
                    <input type="date" id="dob" name="dob" value={formData.dob} 
                        defaultValue={defaultDOB} min={minDOB} max={maxDOB} onChange={handleChange} />
                    {formErrors.dob && <p className="error">{formErrors.dob}</p>}
                    </div>
                
                <div className="form-row">
                    <label htmlFor="alias">Brukernavn</label>
                    <input type="text" id="alias" name="alias" value={formData.alias} onChange={handleChange} />
                    {formErrors.alias && <p className="error">{formErrors.alias}</p>}
                    </div>

                <div className="form-row">
                    <label htmlFor="password">Passord</label>
                    <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} />
                    {formErrors.password && <p className="error">{formErrors.password}</p>}
                    </div>

                <div className="form-row">
                    <label htmlFor="confirmPassword">Bekreft passord</label>
                    <input type="password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                    {formErrors.confirmPassword && <p className="error">{formErrors.confirmPassword}</p>}
                    </div>

                <button type="submit">Registrer</button>
            </form>
        </div>
    );
}

export default Onboarding;